import React from "react";
import { Box, styled } from "@mui/material";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useIntl } from "react-intl";
import { useLocalization } from "gatsby-theme-i18n";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";

import { DefaultLayout, StyledDivider } from "~/views";

const ContentsBox = styled(Box)({
  display: "flex",
  width: "100%",
  maxWidth: 830,
  flexDirection: "column",
  alignSelf: "center",
});

const TableHO = styled(Box)({
  display: "grid",
  gridTemplate: "auto / auto 1fr",
  gridGap: 1,
});

const TableAA = styled(Box)({
  display: "grid",
  gridTemplate: "auto / repeat(4, minmax(0, auto))",
  gridGap: 1,
});

const Title = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#f7f7f7",
  padding: "4px 50px",
  outline: "1px solid #CCCCCC",
});

const Section = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "0 10px",
  outline: "1px solid #CCCCCC",
});

const CenterBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "0 10px",
  outline: "1px solid #CCCCCC",
  textAlign: "center",
  lineBreak: "anywhere",
});

const getLocalizedAddress = (locale, t) => {
  switch (locale) {
    case "ko":
      return (
        <Box>
          {t("address1")}&nbsp;{t("address2")}
        </Box>
      );

    default:
      return (
        <Box>
          {t("address2")},&nbsp;{t("address1")}
        </Box>
      );
  }
};

const getLink = (url, validUrl = true) => {
  return validUrl ? (
    <a href={`//${url}`} target="_blank" rel="noopener noreferrer nofollow">
      {url}
    </a>
  ) : (
    <>{url}</>
  );
};

export default function ContactPage({ data, location }) {
  const { locale } = useLocalization();

  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const agencyList = React.useMemo(() => {
    const t = (id) => formatMessage({ id });

    return [
      {
        company: t("contact-agency-br-company1"),
        contry: t("contact-agency-br"),
        contryCode: "br",
        website: t("contact-agency-br-company1-website"),
        isValidWebsite: true,
      },
      {
        company: t("contact-agency-us-company1"),
        contry: t("contact-agency-us"),
        contryCode: "us",
        website: t("contact-agency-us-company1-website"),
        isValidWebsite: true,
      },
      {
        company: t("contact-agency-in-company1"),
        contry: t("contact-agency-in"),
        contryCode: "in",
        website: t("contact-agency-in-company1-website"),
        isValidWebsite: true,
      },
      {
        company: t("contact-agency-ru-company1"),
        contry: t("contact-agency-ru"),
        contryCode: "ru",
        website: t("contact-agency-ru-company1-website"),
        isValidWebsite: true,
      },
      {
        company: t("contact-agency-cn-company1"),
        contry: t("contact-agency-cn"),
        contryCode: "cn",
        website: t("contact-agency-cn-company1-website"),
        isValidWebsite: false,
      },
    ];
  }, [formatMessage]);

  return (
    <DefaultLayout location={location}>
      <GatsbySeo
        title={locale === "ko" ? "영업 문의" : "Contact"}
        titleTemplate="%s | IMPEC"
        description={t("seo-contact-description")}
      />
      <JsonLd
        json={{
          "@context": "http://schema.org",
          "@type": "Person",
          name: "IMPEC Enterprise Official Pages",
          url: "http://www.impec.com",
          sameAs: ["https://blog.naver.com/impec_enterprise"],
        }}
      />

      <Box style={{ display: "flex", flexDirection: "column" }}>
        <h2>{t("contact")}</h2>
        <ContentsBox>
          <h3>▶ {t("contact-ho")}</h3>
          <TableHO>
            <Title>{t("contact-ho-tel")}</Title>
            <Section>{t("telephone-number")}</Section>

            <Title>{t("contact-ho-fax")}</Title>
            <Section>{t("fax-number")}</Section>

            <Title>{t("contact-ho-email")}</Title>
            <Section>{t("email-sales")}</Section>

            <Title>{t("contact-ho-address")}</Title>
            <Section>{getLocalizedAddress(locale, t)}</Section>
          </TableHO>

          <Box style={{ height: 30 }} />

          <h3>▶ {t("contact-agency")}</h3>
          <TableAA>
            {agencyList.map((agency, index) => (
              <Box
                style={{ display: "contents" }}
                key={`${agency.company}-${index}`}
              >
                <CenterBox>
                  <GatsbyImage
                    image={getImage(data[agency.contryCode])}
                    alt=""
                    objectFit="contain"
                  />
                </CenterBox>
                <CenterBox>{agency.contry}</CenterBox>
                <CenterBox>{agency.company}</CenterBox>
                <CenterBox>
                  {getLink(agency.website, agency.isValidWebsite)}
                </CenterBox>
              </Box>
            ))}
          </TableAA>

          <Box style={{ height: 40 }} />

          <h3>▶ {t("contact-support")}</h3>
          <Box>
            <a
              href={`//${t("contact-teamviewer-url")}`}
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("contact-teamviewer")}
            </a>
          </Box>
          <Box style={{ height: 20 }} />
          <Box>
            <a
              href={`//${t("contact-anydesk-url")}`}
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("contact-anydesk")}
            </a>
          </Box>
        </ContentsBox>
        <StyledDivider />
        <Box style={{ height: 20 }} />
      </Box>
    </DefaultLayout>
  );
}

export const query = graphql`
  query {
    br: file(name: { eq: "national03" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }
    us: file(name: { eq: "national04" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }
    in: file(name: { eq: "national05" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }
    ru: file(name: { eq: "national06" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }
    cn: file(name: { eq: "national02" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }
  }
`;
